
  export const style={
    inpuStyle:{
      colorInput:"#147F40"
    },
    url:{
      urlClient:"https://bosque.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/bosque.png"
    },
    CurrencyName:{
      name:"Noz/Nozes"
    },
    teamName:{
      name:"Bosque"
    },
    hasToken: false,
    hostHeader: "bosque"
  }
